/*
 * @Author: mulingyuer
 * @Date: 2021-09-03 15:25:38
 * @LastEditTime: 2021-09-03 16:45:34
 * @LastEditors: mulingyuer
 * @Description: 课程管理列表
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\api\course-manage\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//分类选项数据
export const category = data => {
  return api({
    url: "/admin/classroom/course_category/all",
    method: "post",
    data
  })
}

//列表
export const list = data => {
  return api({
    url: "/admin/classroom/course/index",
    method: "post",
    data
  })
}


//软删除
export const softDelete = data => {
  return api({
    url: "/admin/classroom/course/softDelete",
    method: "post",
    data
  })
}

//硬删除
export const handleDel = data => {
  return api({
    url: "/admin/classroom/course/del",
    method: "post",
    data
  })
}

//还原
export const putBack = data => {
  return api({
    url: "/admin/classroom/course/putBack",
    method: "post",
    data
  })
}


//修改分类
export const changeCategory = data => {
  return api({
    url: "/admin/classroom/course/updateCategory",
    method: "post",
    data
  })
}

//修改排序
export const changeSort = data => {
  return api({
    url: "/admin/classroom/course/updateSort",
    method: "post",
    data
  })
}

// 老师列表
export const getTeacherList = (data) => {
  return api({
    url: '/admin/college/teacher/index',
    method: 'post',
    data,
  })
}
// 修改上下架
export const updatePublish = (data) => {
  return api({
    url: '/admin/classroom/course/updatePublish',
    method: 'post',
    data,
  })
}
// 批量编辑标签
export const updateThirdCategory = (data) => {
  return api({
    url: '/admin/classroom/course/updateThirdCategory',
    method: 'post',
    data,
  })
}